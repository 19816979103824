import { PageSeoProps } from "@/providers";
import { NextSeo } from "next-seo";
import React from "react";
import getConfig from "next/config";
import NextHead from "next/head";

const { publicRuntimeConfig } = getConfig();

export interface SeoHeadProps extends PageSeoProps {
  keywords?: string | string[];
}

export default function SeoHead({
  title,
  description,
  thumbnail,
  defaultCanonicalUrl,
  keywords = ["test", "test"],
  ...rest
}: SeoHeadProps) {
  return (
    <>
      <NextHead>
        <meta
          name="googlebot"
          content={
            !publicRuntimeConfig.IS_PRODUCTION
              ? "noindex,nofollow"
              : "index,follow"
          }
        />
        <meta
          name="keywords"
          content={
            typeof keywords === "string" ? keywords : keywords?.join(",")
          }
        />
      </NextHead>

      <NextSeo
        additionalMetaTags={[
          {
            httpEquiv: "x-ua-compatible",
            content: "IE=edge; chrome=1",
          },
        ]}
        title={title}
        description={description}
        noindex={!publicRuntimeConfig.IS_PRODUCTION} // noIndex if env isn't production
        nofollow={!publicRuntimeConfig.IS_PRODUCTION} // noFollow if env isn't production
        // languageAlternates={languageAlternates} // add multi-language
        openGraph={{
          title,
          description,
          images: thumbnail && [
            {
              // ensure that it use the same protocol to get the image src from storyblok
              // convert to thumbnail format 16:9
              url: thumbnail,
              alt: title,
            },
          ],
        }}
        canonical={defaultCanonicalUrl}
        {...rest}
      />
    </>
  );
}
